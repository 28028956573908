.generate {
  display: flex;
  background-color: $common-color;
  position: relative;
  z-index: 1;

  &__container {
    width: 1092px;
    margin: auto;
    padding: 170px 0 210px;
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @include for-small-desktop {
      padding: 139px 20px 150px;
    }

    @include for-tablet {
      padding: 50px 20px 100px;
      flex-wrap: wrap;
      gap: 40px;
    }
  }

  &__information {
    max-width: 350px;
    color: #c4c4c4;
    margin-top: 20px;
  }

  &__title {
    color: $white;
    font-size: 36px;
    line-height: 115%;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 24px;
  }

  &__list-title {
    font-size: 24px;
    margin-bottom: 24px;
  }

  &__list {
    padding-left: 20px;
  }

  &__list-item {
    list-style-type: disc;
  }

  &__price-text {
    margin-top: 80px;
    line-height: 150%;
  }

  &__img {
    width: 450px;
    margin-right: 30px;
    margin-left: 100px;

    @include for-phone {
      width: 100%;
    }
  }
}
