.nft-item {
  width: 144px;
  position: relative;

  &__img {
    width: 144px;
    height: auto;
    border-radius: 5px;
  }

  &:hover {
    cursor: pointer;

    .nft-item__info {
      opacity: 1;
    }
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  &__info {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 20;
  }
}
