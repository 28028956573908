.nearkats-list {
  display: flex;
  margin-left: 50px;
  gap: 30px;
  position: relative;

  @include for-laptop {
    margin-left: 0;
  }
}
