.learn {
  display: flex;
  justify-content: space-between;
  padding: 130px 0 170px;
  width: 992px;
  margin: auto;

  @include for-laptop {
    width: 100%;
    padding: 130px 30px 170px;
  }

  @include for-tablet {
    padding: 50px 30px 100px;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include for-phone {
    padding: 50px 20px 100px;
  }

  &__title {
    font-size: 36px;
    line-height: 115%;
    color: $secondary-color;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 16px;
    line-height: 150%;
    color: $common-color;
    margin-bottom: 16px;
    max-width: 345px;
  }

  &__picture {
    position: relative;
    pointer-events: none;

    @include for-phone {
      height: fit-content;
    }
  }

  &__img {
    position: relative;
    z-index: 10;

    @include for-phone {
      width: 100%;
    }
  }

  &__background-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    @include for-phone {
      svg {
        width: 100%;
      }
    }
  }

  &__background-ellipse {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    @include for-phone {
      width: 100%;

      svg {
        width: 100%;
      }
    }
  }
}
