.social-links {
  display: flex;
  gap: 20px;

  &__item {
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}
