// Colors HEX
$white: #ffffff;

$common-color: #262626;
$secondary-color: #3f4246;
$purpure-color: #6b6ef9;
$burgundy: #cb015b;
$burgundy-hover: #ff007f;

// font
$common-font: 'Manrope', sans-serif;

// size
$block-width: 992px; ;
