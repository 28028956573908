.generate-count-btn {
  border-radius: 100px;
  width: 78px;
  height: 78px;
  background: #54c4f6;
  font-size: 20px;
  line-height: 115%;
  transition: box-shadow 0.5s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 30px #54c4f6;
  }

  &__many {
    background: #281a6f;

    &:hover {
      box-shadow: 0px 0px 30px #54c4f6;
    }
  }

  &__active {
    box-shadow: 0px 0px 30px #54c4f6;
    font-size: 28px;

    &:hover {
      box-shadow: 0px 0px 30px #54c4f6;
    }
  }
}
